
import { Component, Prop } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import GeneralForm from '@/components/coupon/form/GeneralForm.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { Article as ArticleModel } from '@/interfaces/models/Article';
import { AxiosResponse } from 'axios';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { User } from '@/interfaces/models/User';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Permission } from '@/enums/Permission';
import { CustomerGroup } from '@/enums/CustomerGroup';
import AvailabilityForm from '@/components/coupon/form/AvailabilityForm.vue';
import { Coupon } from '@/interfaces/models/Coupon';
import EditCouponLoader from '@/components/coupon/EditCouponLoader.vue';
import SimpleGeneralForm from '@/components/coupon/form/SimpleGeneralForm.vue';
import ImageForm from '@/components/coupon/form/ImageForm.vue';
import { PiggyReward } from '@/interfaces/models/PiggyReward';

const coupon = namespace('coupon');
const app = namespace('app');
const auth = namespace('auth');
const reward = namespace('reward');
const venue = namespace('venue');
const label = namespace('venueLabel');

@Component({
  components: { SimpleGeneralForm, EditCouponLoader, GeneralForm, VWrapper, AvailabilityForm, ImageForm },
})
export default class EditCoupon extends mixins(Editable, Notification, StackedForm) {
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;

  public $refs!: {
    general: InstanceType<typeof GeneralForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
    image: InstanceType<typeof ImageForm> & { getData: () => any };
    availability: InstanceType<typeof AvailabilityForm> & { getData: () => any };
  };

  @app.State('venues') public venues!: Venue[];
  @auth.State('user') public authUser!: User;
  @venue.Action('fetch') public getVenues!: any;
  @coupon.State('active') public active!: Coupon;
  @label.State('items') public labels!: VenueLabel[];
  @label.Action('fetch') public getVenueLabels!: any;

  @coupon.Action('uploadGiftCards') public uploadGiftCards!: any;
  @coupon.Action('store') public store!: any;
  @coupon.Action('generate') public generateCodes!: any;
  @coupon.Action('show') public show!: any;
  @coupon.Action('update') public update!: any;

  @coupon.Action('uploadCouponImage') public uploadImage!: any;
  @coupon.Action('uploadCouponBanner') public uploadBanner!: any;

  @reward.Action('getByCustomerGroup') public getRewards!: any;
  @reward.Action('resetRewards') public resetRewards!: any;
  @reward.Action('setFilter') public setRewardsFilter!: any;
  @reward.State('items') public rewards!: PiggyReward[];

  public venuesByGroupAndCountry: Venue[] = [];
  public articles: Array<Partial<ArticleModel>> = [];
  private activeCustomerGroup: CustomerGroup | undefined;

  get customerGroup() {
    if (this.authUser.customerGroup && this.authUser.customerGroup !== CustomerGroup.Default) {
      this.activeCustomerGroup = this.authUser.customerGroup as CustomerGroup;
      return this.authUser.customerGroup;
    }
    if (this.authUser.linkedCustomerGroup && this.authUser.linkedCustomerGroup !== CustomerGroup.Default) {
      this.activeCustomerGroup = this.authUser.customerGroup as CustomerGroup;
      return this.authUser.linkedCustomerGroup;
    }

    return null;
  }

  get coupon() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get filteredRewards() {
    return this.rewards.map((reward: PiggyReward) => {
      reward.disable = false;
      return reward;
    });
  }

  get title() {
    return this.editing ? 'coupon.edit' : 'coupon.create';
  }

  get venuesSorted(): Venue[] {
    return this.venuesByGroupAndCountry;
  }

  public async mounted() {
    this.$startLoading('coupon');
    await this.resetRewards();
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }
    if (this.editing) {
      await this.show({ id: this.id });
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'coupon.index' });
    }
    this.$stopLoading('coupon');
    this.venuesByGroupAndCountry = this.venues;
  }

  public async onCountriesOrCustomerGroupChange(data: { countries: string[]; customerGroup: CustomerGroup }) {
    const customerGroup: CustomerGroup = data.customerGroup
      ? data.customerGroup
      : (this.customerGroup as CustomerGroup);

    this.activeCustomerGroup = customerGroup;

    if (data.countries.length) {
      this.venuesByGroupAndCountry = this.venues.filter((venue: Venue) => {
        if (data.countries.find((country: string) => venue.country === country)) {
          return venue;
        }
      });
    } else {
      this.venuesByGroupAndCountry = this.venues;
    }

    if (customerGroup) {
      this.venuesByGroupAndCountry = this.venuesByGroupAndCountry.filter(
        (venue: Venue) => venue.customerGroup === customerGroup,
      );
    }
  }

  public async onVenuesChange(data: { venues: string[]; countries: string[]; customerGroup: CustomerGroup }) {
    const foodcardApi: FoodcardApiService = new FoodcardApiService();
    let articles: Array<Partial<ArticleModel>> = [];
    const isPiggyLoyaltyEnabled: boolean = this.venues
      .filter((venue: Venue) => data.venues.includes(venue._id))
      .every((venue: Venue) => !!venue.piggyLoyaltyEnabled);

    if (isPiggyLoyaltyEnabled) {
      this.fetchRewards(this.activeCustomerGroup as CustomerGroup);
    } else {
      this.resetRewards();
    }

    this.$startLoading('article');
    for (const id of data.venues) {
      const articleRes: AxiosResponse<Array<Partial<ArticleModel>>> = await foodcardApi.getArticleNames({
        venue: id,
      });
      articles = [...articles, ...articleRes.data];
    }
    this.articles = articles;
    this.$stopLoading('article');
  }

  private async fetchRewards(customerGroup: CustomerGroup) {
    this.setRewardsFilter({ customerGroup });
    await this.getRewards();
  }

  public save() {
    this.$refs.general.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        /* this.$startLoading('coupon.save');*/
        if (this.editing) {
          await this.update({ ...this.getMergedData(), id: this.active!._id });
          await this.handleUploadCsv();
        } else {
          if (this.multiple) {
            // @ts-ignore
            const data: Partial<Coupon> & { generateAmount: number } = { ...this.getMergedData() };
            await this.generateCodes({ code: data, amount: data.generateAmount });
          } else {
            await this.store({ ...this.getMergedData() });
            await this.handleUploadCsv();
          }
        }
        const data: Partial<Coupon> = this.getMergedData();
        data.id = this.active._id;
        let formData: FormData;
        if (data.image && data.image instanceof File) {
          formData = new FormData();
          formData.append('asset', data.image);
          await this.uploadImage({ ...data, image: formData });
        }
        /*this.$stopLoading('coupon.save');*/
        this.$router.push({ name: 'coupon.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  protected getMergedData(): Partial<Coupon> {
    return {
      ...this.$refs.general.getData(),
      ...this.$refs.image.getData(),
      availability: {
        ...this.$refs.availability.getData(),
      },
    };
  }

  private async handleUploadCsv(): Promise<void> {
    const formData: FormData = new FormData();
    const uploadedCsv = this.$refs.general.getData().uploadedGiftCards;
    const needToUpload = uploadedCsv && uploadedCsv instanceof File;

    if (needToUpload && uploadedCsv) {
      formData.set('file', uploadedCsv);
      await this.uploadGiftCards({ id: this.active!._id, file: formData });
    }
  }
}
